import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Stroopwafelactie 2018',
        date: moment('2018-09-28 13:37'),
        image: image,
        summary: (
            <>
                In september 2018 mochten we als Sarfath, samen met Sunrise
                Dieze en het project Kandila in Roemenie, een stroopwafelactie
                houden.
            </>
        ),
        content: (
            <>
                <p>
                    In september 2018 mochten we als Sarfath, samen met Sunrise
                    Dieze en het project Kandila in Roemenie, een
                    stroopwafelactie houden.
                </p>

                <p>
                    Binnen de Jeruzalemkerkgemeente zijn er vele stroopwafels
                    verkocht en gegeten. In een maand tijd en door inzet van
                    vele vrijwilligers bracht deze actie ruim €2100,00 op, mede
                    door een extra bijdrage van de Centrale diaconie van PKN
                    Zwolle. Zo konden we met elkaar mensen in Zwolle, Roemenië
                    en de Arabische wereld steunen. Een mooi bewijs van: Vele
                    handen maken licht werk!
                </p>

                <p>
                    Als bestuur van Sarfath, zijn we dankbaar en blij dat we met
                    elkaar zo geld bij elkaar mogen brengen voor de
                    verschillende projecten. Dank jullie wel allemaal.
                    <br />
                </p>
            </>
        ),
    });

export default useStory;
