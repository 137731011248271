import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';
import image_3 from './image_3.jpg';

const useStory = () =>
    useArticle({
        title: 'Ontbijtactie Valentijnsdag 2021',
        date: moment('2021-05-24 14:06'),
        image: image,
        summary: (
            <>
                Omdat in januari de Goede Doelen maaltijd in verband met Corona
                niet door kon gaan, is er een ontbijtactie gehouden.
            </>
        ),
        content: (
            <>
                <p>
                    <strong>
                        Een ontbijtactie voor Valentijnsdag in de CGK-Zwolle, op
                        13 februari 2021
                    </strong>
                </p>

                <p>
                    Omdat in januari de Goede Doelen maaltijd in verband met
                    Corona niet door kon gaan, is er een ontbijtactie
                    gehouden.&nbsp; Tot 200 ontbijtjes waren door verschillende
                    bedrijven gesponsord, maar de bestellingen kwamen uit op 260
                    ontbijtjes. We waren dus blij verrast, door de enthousiaste
                    reacties in de gemeente, maar moesten met elkaar dus
                    improviseren.&nbsp; Op vrijdag en zaterdag zijn de pakketten
                    samengesteld en zaterdagmiddag rondgebracht. Velen handen
                    maakten licht werk. Voor veel mensen kwam het ontbijtje als
                    verrassing. Er konden ontbijtjes worden weggegeven aan
                    vrienden en bekenden, maar ook aan minima. En natuurlijk
                    gewoon voor je eigen huishouden.
                </p>

                <p>
                    De opbrengst was €1.771,57. Delen door 9 stichtingen, bracht
                    dit het mooie bedrag van €196,84 per stichting op.
                </p>

                <img className="img-fluid" src={image_2} alt="" />

                <img
                    className="img-fluid"
                    width="1024"
                    height="768"
                    src={image_3}
                    alt=""
                />

                <p></p>
            </>
        ),
    });

export default useStory;
