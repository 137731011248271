import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Diensten veiling CGK Zwolle 2019',
        date: moment('2019-12-01 09:41'),
        image: image,
        summary: (
            <>
                Door leden van de CGK Zwolle zijn allerlei verschillende
                diensten aangeboden van taarten bakken tot bootuitjes en van
                werken in de tuin tot bij een ander gezellig uit dineren.
            </>
        ),
        content: (
            <>
                <p>
                    Door leden van de CGK Zwolle zijn allerlei verschillende
                    diensten aangeboden van taarten bakken tot bootuitjes en van
                    werken in de tuin tot bij een ander gezellig uit dineren. Al
                    deze diensten zijn opgenomen in een Dienstenboekje. Het werd
                    een kleurig, veelzijdig geheel, van ruim 70 aanbiedingen.
                    Tot 11 november kon hier via de site en telefonisch op
                    geboden worden. Op verschillende aanbiedingen werd vrolijk
                    tegen elkaar opgeboden. Dit gaf de mooie opbrengst van
                    ongeveer €3.300,00 die we dan met ons achten mogen verdelen.
                    Aangezien we bij de CGK Zwolle met acht Goede Doelen
                    samenwerken.
                </p>
            </>
        ),
    });

export default useStory;
