import './index.scss';
import imageProjects from '../../../assets/parallax-8.jpg';

function Projects() {
    return (
        <div className="page-projects my-5">
        <div className="container text-start">
            <p>
                <strong>
                    Welke projecten/activiteiten draaien er op dit moment bij
                    MensRijk?
                </strong>
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>
                <strong>Kleuterklas (3-5 jaar)</strong>
            </p>
            <p>
                Een groepje van 10-12 kinderen komt&nbsp; 5 dagen per week naar
                het centrum van MensRijk.
            </p>
            <p>
                Het juiste materiaal en de juiste begeleiding moeten ervoor
                zorgen dat de kinderen leren wat bij hun leeftijd en hun
                ontwikkeling past. Lokaal getrainde en gecoachte juffen geven de
                kinderen, die voornamelijk uit achterstandssituaties komen, les
                vanuit de positieve benadering en de opvoedkundige ideeën van
                Montessori. Dit in tegenstelling tot de lokale kleuterscholen
                waar straffen en belonen centraal staan in de opvoeding.
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
                <li>
                    <strong> Vrolijk Leren groep (5-6 jaar)</strong>
                </li>
            </ol>
            <p>
                Veel kinderen van 5 jaar zitten nog thuis of gaan naar een
                traditionele kleuterschool. Daar krijgen kinderen lezen,
                schrijven, rekenen en Engels volgens het traditionele systeem.
            </p>
            <p>MensRijk gaat voor leren door bewegen en leren door spelen.</p>
            <p>
                Zo worden kinderen in kleine groepjes opgevangen om zich voor te
                bereiden op de lagere school, waar ze vanaf 6 jaar naar toe
                kunnen.
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
                <li>
                    <strong> Vrolijk Leren groep (7-15 jaar)</strong>
                </li>
            </ol>
            <p>
                Meer dan&nbsp; 50% van de kinderen op de lokale scholen hebben
                problemen met lezen. MensRijk heeft een programma dat bestaat
                uit 6 niveaus, waarin kinderen kunnen instromen op hun eigen
                niveau om zo in leesvaardigheid, cognitieve ontwikkeling,
                psychisch emotionele en&nbsp; sociale ontwikkeling te groeien.
                De kinderen werken in groepjes van 3-6 leerlingen en worden 3
                keer per week 3 uur begeleid door lokale medewerkers die binnen
                MensRijk getraind zijn.
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
                <li>
                    <strong>
                        {' '}
                        Groepstherapie jonge kinderen – problemen in
                        ontwikkeling (tot 8 jaar)
                    </strong>
                </li>
            </ol>
            <p>
                Verschillende types kinderen met
                ontwikkelingsstoornissen/problemen komen op ons pad.
            </p>
            <p>
                De kinderen worden 3 keer per week opgevangen in kleine groepjes
                ( 3-5 kinderen) om o.l.v. een psychologe en&nbsp; een assistent
                intensief begeleid en gestimuleerd&nbsp; te worden bij hun
                achterstand of probleem.
            </p>
            <p>&nbsp;</p>
            <ol start={5}>
                <li>
                    <strong> Ouderbegeleiding ( verbonden met 4 )</strong>
                </li>
            </ol>
            <p>
                De ouders van kinderen met ontwikkelingsstoornissen/problemen
                ontvangen 2 x per maand begeleiding en consultancy. Deze
                begeleiding is gefocust op een positieve opvoeding , gezonde
                routines en leefstijl en specifieke adviezen voor de problemen
                van hun kind.
            </p>
            <p>&nbsp;</p>
            <ol start={6}>
                <li>
                    <strong>
                        {' '}
                        Volwassenen leren lezen ( vrouwen 15 jaar en ouder )
                    </strong>
                </li>
            </ol>
            <p>
                Vrouwen in de wijk, die in hun kinderjaren niet naar school zijn
                geweest of geen schoolloopbaan hebben afgemaakt, komen 2x per
                week voor leeslessen. Er zijn 3 verschillende niveaus. Er wordt
                per schooljaar 1 niveau afgerond. Op dit moment komen er 10
                vrouwen per week langs.
            </p>
            <p>
                Het zijn gezellige lessen, waarbij behalve aan lezen ook aan
                persoonlijkheidsvorming wordt gewerkt.
            </p>
            <p>&nbsp;</p>
            <ol start={7}>
                <li>
                    <strong>
                        {' '}
                        Workshops voor moeders ( opvoeding en persoonlijke
                        vorming ){' '}
                    </strong>
                </li>
            </ol>
            <p>
                Wekelijkse workshops voor moeders worden verzorgd door een
                psycholoog. Die bespreekt met de moeders onderwerpen over de
                ontwikkeling en opvoeding van kinderen en de persoonlijke
                problemen van de moeders in dat proces.
            </p>
            <p>&nbsp;</p>
            <ol start={8}>
                <li>
                    <strong>
                        {' '}
                        Trainingen en workshops voor kleuterleidsters
                    </strong>
                </li>
            </ol>
            <p>
                Meer dan 90% van de kleuterleidsters&nbsp; in de wijk hebben
                geen opleiding gehad. MensRijk verzorgt regelmatig trainingen,
                die hun vaardigheden en hun kennis over de omgang&nbsp; met het
                kind in de ontwikkelingsfases met specifieke behoeften
                verbeteren. (Denken en omdenken, wat te doen met over
                beweeglijke kinderen, hoe kun je hun gedrag veranderen etc.)
            </p>
            <p>
                Tevens&nbsp; zijn er workshops over de verschillende programma’s
                die MensRijk&nbsp; heeft voor kleuters met het doel het
                onderwijs te verbeteren.
            </p>
            <p>&nbsp;</p>
            <ol start={9}>
                <li>
                    <strong> Logopedie-voor jonge en oudere kinderen.</strong>
                </li>
            </ol>
            <p>
                Kinderen met spraak- en taalachterstanden of problemen in hun
                ontwikkeling komen&nbsp; wekelijks langs. De logopediste werkt
                samen met de moeder. Moeder oefent de uitgevoerde oefeningen
                dagelijks thuis.
            </p>
            <p>&nbsp;</p>
            <ol start={10}>
                <li>
                    <strong>
                        {' '}
                        Winterkamp (5-7 jaar/ 7-11 jaar/ 11-15 jaar)
                    </strong>
                </li>
            </ol>
            <p>
                Tijdens de maanden januari en februari zijn de scholen gesloten.
                Dit varieert van 2 tot 4 weken. MensRijk opent dan de deuren
                voor de kinderen van de wijk om ze ruimte te geven een gezellige
                tijd te hebben waarin ze kunnen spelen en creatief bezig zijn.
                Elke leeftijdsgroep heeft een eigen programma. Het programma
                heeft een thema dat geschikt is voor het kind.
            </p>
            <p>
                Belangrijke onderdelen zijn: leren samenwerken, samen delen,
                samen praten, elkaar respecteren.
            </p>
            <p>
                De leiders komen voornamelijk uit de wijk en hebben een “in
                house training” gehad.
            </p>
            <p>&nbsp;</p>
            <ol start={11}>
                <li>
                    <strong>
                        {' '}
                        Zomerkamp (5-7 jaar/ 7-11 jaar/ 11-15 jaar )
                    </strong>
                </li>
            </ol>
            <p>
                Tijdens de lange , hete zomers is het een verademing voor het
                jonge kind,&nbsp; het schoolkind en de tiener om een paar dagen
                per week het huis uit te mogen en creatief bezig te kunnen zijn
                bij MensRijk. Het zomerkamp is opgezet rond een wekelijks&nbsp;
                thema, waarbij elke leeftijdsgroep&nbsp; nieuwe kennis opdoet en
                geniet van samen werken en spelen. De begeleiding wordt gedaan
                door studenten en moeders uit de wijk.
            </p>
            <p>
                Basisprincipes:&nbsp; ieder mens is waardevol. Heb respect voor
                jezelf, de ander en de omgeving.
            </p>
            <p>&nbsp;</p>
            <ol start={12}>
                <li>
                    <strong> Opvang van “verdwaalde tieners”- in opbouw</strong>
                </li>
            </ol>
            <p>
                In een wijk met veel een- of tweekamer woningen, waar geen
                mogelijkheid is om leeftijdgenoten bij een club of
                sportvereniging te ontmoeten, zie je jongens en meiden over
                straat&nbsp; zwalken om “hun tijd te doden”. Deze tieners zijn
                elk moment van de dag welkom&nbsp; en op specifieke momenten is
                er een begeleidster die met hen creatief werk doet en gesprekken
                voert. &nbsp;MensRijk wil er voor hen zijn.
            </p>
            <p>&nbsp;</p>
            <ol start={13}>
                <li>
                    <strong>
                        {' '}
                        Begeleiden van projecten van “Vrolijk leren” in hun
                        eigen omgeving.
                    </strong>
                </li>
            </ol>
            <p>
                (kerk, moskee of non -profit organisatie&nbsp; in een
                achtergestelde wijk)- veel kinderen hebben leer- en
                leesproblemen.
            </p>
            <p>MensRijk verzorgt een jaar lang:</p>
            <p>
                -Trainingen voor het team- persoonlijke vorming, kennis over
                programma’s kinderpsychologie en&nbsp; gedragsproblemen,
                teambuilding
            </p>
            <p>
                -Support voor het opzetten van de administratie en organisatie
                van het project.
            </p>
            <p>-Supervisie en coaching van de leider van het project.</p>
            <p>&nbsp;</p>
            <ol start={14}>
                <li>
                    <strong> Kids Skills </strong>
                </li>
            </ol>
            <p>
                Een programma om te leren “oplossingsgericht” te werken met
                kinderen en ouders.
            </p>
            <p>
                Voorbereidingsfase en uitvoeringsfases van het project ( 3-4
                jaar0
            </p>
            <ol>
                <li>Vertaling van materiaal voor training, website, app</li>
                <li>Website info</li>
                <li>Vertalen, editen en uitgeven van een begeleidend boek</li>
                <li>Adverteren voor workshops en trainingen</li>
                <li>
                    Trainen van eigen werkers en outsiders om met het programma
                    te werken
                </li>
                <li>Uitvoeren van het programma-thuis en op scholen</li>
            </ol>
            <p>&nbsp;</p>
            <p>
                Het doel van het programma is om te leren op een positieve
                manier met elkaar om te gaan, om te leren elkaar te ondersteunen
                en altijd oplossingsgericht te denken.
            </p>
            <p>Relaties versterken door samen te praten.</p>
            <p>&nbsp;</p>
            <ol start={15}>
                <li>
                    <strong>
                        {' '}
                        Bewegen is goed!- Voor vrouwen van elke leeftijd.
                    </strong>
                </li>
            </ol>
            <p>
                Een wekelijkse activiteit om vrouwen de kans te geven meer te
                bewegen en contact te hebben met andere jonge vrouwen.
            </p>
            <p>
                Een van de vrouwen neemt zelf de leiding met behulp van een you
                tube&nbsp; clip.
            </p>
            <p>&nbsp;</p>
            <ol start={16}>
                <li>
                    <strong> Green Arabia</strong>
                </li>
            </ol>
            <p>
                Een programma over vervuiling, hoe omgaan met het milieu,
                hergebruik van materiaal, recycling etc.
            </p>
            <p>
                De boekjes zijn geschikt voor kinderen van 5 – 12 jaar en de
                handleiding voor de leidsters beschrijft 6 lessen. MensRijk wil
                in het komend jaar 200 kinderen bereiken om bewustwording en
                gedragsverandering in beweging te zetten. Er is een
                ganzenbordspel&nbsp; gemaakt om de kinderen te helpen&nbsp;
                herinneren wat het effect is van een vervuilde omgeving en wat
                het resultaat is van een schone omgeving.
            </p>
            <p>&nbsp;</p>
            <ol start={17}>
                <li>
                    <strong>
                        {' '}
                        Workshop en supportgroepen voor jonge moeders-“Hoe om te
                        gaan met mijn jonge kind?”
                    </strong>
                </li>
            </ol>
            <p>
                In 4 workshops krijgen jonge moeders in notendop inzicht in de
                behoeften van hun jonge kind ( 0 tot 3 jaar ). En hoe je met
                jonge kinderen moet omgaan.
            </p>
            <p>
                Ze leren dat een gestructureerde omgeving, een consequente
                aanpak en het opbouwen van een goede relatie de basis is voor
                een gezonde ontwikkeling. Stuk voor&nbsp; stuk elementen die
                nauwelijks of niet in deze cultuur/ samenleving aanwezig zijn.
            </p>
            <p>
                Na de 4 workshops gaan we verder in kleine ondersteunende
                groepjes, die 4-10 keer (afhankelijk van de behoefte van de
                moeders) bij elkaar komen.
            </p>
            <p>&nbsp;</p>
            <ol start={18}>
                <li>
                    <strong> Familietherapie</strong>
                </li>
            </ol>
            <p>
                Kinderen opvoeden is niet gemakkelijk. Regelmatig komen ouders
                klagen over het wangedrag van hun kinderen. Slaan en ander
                gewelddadig gedrag van de ouders vermindert dit niet.
            </p>
            <p>
                In de meeste gevallen is het belangrijk dat de ouders leren op
                een andere manier naar hun kind te kijken. Belangrijk om met
                hernieuwd elan met hun kinderen om te gaan. Het gevolg is dat
                hun kind anders gaat reageren.
            </p>
            <p>
                Vanaf september komen er wekelijks families bij MensRijk, die
                door een psycholoog en een educatief werker worden begeleid om
                “vrede” en goede relaties in hun gezin op te bouwen.
            </p>
            <p>Alle gezinsleden worden hierbij betrokken.</p>
            <p>
                Het is bijzonder om te zien hoe elk gezinslid geïnteresseerd is
                om “samen” aan een nieuw&nbsp; leven te bouwen.
            </p>
        </div>

        <div className='w-100 mt-5'>
            <img className='img-fluid w-100' src={imageProjects} alt="projects" />
        </div>

        </div>
    );
}

export default Projects;
