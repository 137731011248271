import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.png';

const useStory = () =>
    useArticle({
        title: 'Rommelmarkt CGK Zwolle 2018',
        date: moment('2018-05-13 13:36'),
        image: image,
        summary: (
            <>
                De Christelijk Gereformeerde Kerk in Zwolle, hield in mei 2018
                met de 8 Gezamenlijke Goede Doelen, waaronder Sarfath een grote
                Rommelmarkt.
            </>
        ),
        content: (
            <>
                <p>
                    De Christelijk Gereformeerde Kerk in Zwolle, hield in mei
                    2018 met de 8 Gezamenlijke Goede Doelen, waaronder Sarfath
                    een grote Rommelmarkt.
                </p>

                <img
                    className="img-fluid"
                    width="330"
                    height="248"
                    src={image_2}
                    alt=""
                />

                <p>
                    Een aantal dagen van te voren werd er door velen gehoor
                    gegeven aan de oproep om spullen af te leveren bij de
                    Verrijzeniskerk. En op zaterdagmorgen vroeg waren we met
                    velen in touw om alles buiten een plek te geven, want we
                    hadden schitterend weer. Met een gezellige Koek en Zopie
                    hoek, een kinderhoek en heel veel geschikte spullen voor
                    hergebruik, kunnen we terug zien op een schitterende dag,
                    met ook een mooie opbrengst van ruim €3200,00. Natuurlijk
                    moeten we dat dan met ons achten delen, maar dan nog zijn we
                    blij met dit mooie bedrag van €400,00 per stichting.
                </p>
            </>
        ),
    });

export default useStory;
