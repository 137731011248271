import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';

const useStory = () =>
    useArticle({
        title: 'Goede doelen markt 2016',
        date: moment('2016-09-04 11:50'),
        image: image,
        summary: (
            <>
                Op zaterdag 10 september 2016 wordt gevierd dat in 1991 de
                Zuiderhof in gebruik is genomen als preeklocatie van de CGK in
                Zwolle.
            </>
        ),
        content: (
            <>
                <p>
                    Op zaterdag 10 september 2016 wordt gevierd dat in 1991 de
                    Zuiderhof in gebruik is genomen als preeklocatie van de CGK
                    in Zwolle.
                </p>

                <p>
                    In de afgelopen 25 jaar is er veel gebeurd waar de gemeente
                    God dankbaar voor is! De gemeente is enorm gegroeid en dat
                    is ook te zien in de Zuiderhof, waar sinds een heel aantal
                    jaren ’s morgens dubbele diensten gehouden worden.
                </p>

                <ul>
                    <li>Zaterdagmiddag 10 september</li>

                    <li>14.30 – 17.00 uur</li>
                </ul>

                <p>
                    Er wordt een gezellige high tea georganiseerd waarin tijd is
                    voor ontmoeting, met daarnaast diverse leuke activiteiten
                    voor kinderen in de basisschoolleeftijd. Er vind een
                    presentatie/verkoop voor goede doelen plaats. Stichtingen
                    presenteren zich in de vorm van een goede doelen markt.
                    Stichting Sarfath zal zich ook presenteren door een leuke
                    kraam in te richten.
                </p>

                <img
                    className="img-fluid"
                    width="300"
                    height="200"
                    src={image_2}
                    alt=""
                />

                <p>
                    <a href="https://cgkzwolle.nl/nieuws/1019/de-zuiderhof-25-jaar-dat-is-een-feestje-waard">
                        https://cgkzwolle.nl/nieuws/1019/de-zuiderhof-25-jaar-dat-is-een-feestje-waard
                    </a>
                </p>
            </>
        ),
    });

export default useStory;
