import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Speculaasactie Jeruzalemkerk Zwolle 2024',
        date: moment('2024-11-28 10:00'),
        image: image,
        summary: (
            <>
                Met elkaar mochten we weer speculaasbrokken en -molens kopen en
                verkopen. Door mee te doen met deze actie hebben we dit jaar met
                elkaar €1050,00 bijeengebracht! Allemaal hartelijk dank en dan
                denk ik ook aan al die mensen die gesponsord hebben en giften
                hebben gegeven.
            </>
        ),
        content: (
            <>
                <div className="row mb-3">
                    <div className="col-12 col-xs-6 col-md-4">
                        <img
                            src={image}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                    <div className="col">
                        Met elkaar mochten we weer speculaasbrokken en -molens
                        kopen en verkopen. Door mee te doen met deze actie
                        hebben we dit jaar met elkaar €1050,00 bijeengebracht!
                        Allemaal hartelijk dank en dan denk ik ook aan al die
                        mensen die gesponsord hebben en giften hebben gegeven.
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
