import { useCallback, useEffect, useState } from 'react';
import './index.scss';
import iconDonation from '../../../assets/icon-donation-grey.png';
import iconDove from '../../../assets/icon-dove-grey.png';
import iconPraying from '../../../assets/icon-praying-grey.png';
import Payment from '../../../models/Payment';
import PaymentCheckout from '../../../models/PaymentCheckout';
import PaymentResponse from '../../../models/PaymentResponse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellOn } from '@fortawesome/pro-solid-svg-icons';

function Donate({ payment }: { payment?: string }) {
    const [amount, setAmount] = useState(15);
    const [message, setMessage] = useState<string>();

    const pay = useCallback(async () => {
        if (!amount) return;

        const data: Payment = {
            amount,
            redirect: window.location.origin,
        };

        const response = await fetch(`${process.env.PUBLIC_URL}/_donate.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: '*/*',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            setMessage('Er is iets fout gegaan tijdens het verwerken van uw donatie. Probeert u het a.u.b. later nog eens. Onze excuses voor het ongemak.');
            return;
        }

        const checkout = (await response.json()) as PaymentCheckout;
        window.location.href = checkout.checkout;
    }, [amount]);

    const check = useCallback(async () => {
        if (!payment) return false;

        try {
            const response = await fetch(
                `${process.env.PUBLIC_URL}/_donation.php?id=${payment}`
            );
            if (!response.ok) return false;
    
            const data = (await response.json()) as PaymentResponse;
            console.log(data);
    
            return data.paid;
        } catch {
            return false;
        }

    }, [payment]);

    useEffect(() => {
        if (payment)
            check().then((paid) => {
                if (paid) {
                    setMessage('Bedankt voor uw donatie!');
                } else {
                    setMessage('Er is iets fout gegaan tijdens het verwerken van uw donatie. Probeert u het a.u.b. later nog eens. Onze excuses voor het ongemak.');
                }
            });
    }, [check, payment]);

    return (
        <div className="page-donate my-5">
            <div className="container text-start">
                <p>
                    Wil je onderdeel zijn van onze missie en ondersteunen met
                    een financiële bijdrage, dan kan dat op drie manieren:
                </p>
                <ol type="1">
                    <li>
                        Maak hieronder het gewenste bedrag over met IDEAL (zelf
                        een bedrag kiezen).
                    </li>
                    <li>
                        Maak handmatig een gift over naar bankrekeningnummer
                        NL87SNSB0952007517 ten name van Stichting Sarfath onder
                        vermelding van Gift voor Stichting Sarfath.
                    </li>
                    <li>
                        Voor de continuïteit zijn wij opzoek naar sponsoren die
                        met een periodieke gift een fundament willen leggen
                        onder dit mooie werk. Dit kun je zelf instellen bij je
                        bank.
                    </li>
                </ol>
                <p>
                    STICHTING SARFATH’ beschikt over een ANBI-status (een
                    Algemeen Nut Beogende Instelling). Al je giften zijn daarom
                    mogelijk aftrekbaar bij de inkomstenbelasting.
                </p>
                <p>Alvast bedankt voor je bijdrage!</p>
            </div>
            <div className="container text-start border-top pt-3 mt-5">
                <h5>
                    Betaal hier met IDEAL (klik op ‘€15’ om een ander bedrag in
                    te vullen):
                </h5>

                <div className="form-group">
                    <label>
                        Bedrag (EUR) <span className="text-danger">*</span>
                    </label>
                    <input
                        className="form-control"
                        type="number"
                        min="0.00"
                        max="10000.00"
                        step="0.1"
                        value={amount}
                        onChange={(e) => {
                            const amount = Number(
                                e.target.valueAsNumber.toFixed(2)
                            );
                            setAmount(amount);
                        }}
                    />
                </div>

                <button
                    className="btn btn-secondary text-light mt-3"
                    disabled={!amount}
                    onClick={() => pay()}>
                    Doneren
                </button>
            </div>
            <div className="container text-start border-top pt-3 mt-5">
                <p>
                    Geld is nodig om lesmateriaal te kunnen kopen en praktische
                    hulp te bieden aan mindervaliden en andersbegaafden. Bid
                    daarnaast als je wilt ook met ons mee:
                </p>
                <ol type="1">
                    <li>
                        Bid dat de mensen in de Arabische wereld Jezus mogen
                        leren kennen.
                    </li>
                    <li>
                        Bid dat ook de (arme) kinderen in de Arabische wereld,
                        die om welke reden dan ook niet mee kunnen komen,
                        toegang krijgen tot onderwijs.
                    </li>
                    <li>
                        Bid mee voor een hoopvolle toekomst voor kinderen,
                        mindervaliden en andersbegaafden in de Arabische wereld.
                    </li>
                </ol>
                <p>
                    Heb je ervaring in lesgeven of in het ontwikkelen van
                    lesmateriaal? Dan kunnen we je hulp misschien wel goed
                    gebruiken voor het ontwikkelen van nieuw lesmateriaal en het
                    lesgeven aan kinderen in de Arabische wereld of het trainen
                    van leraren. Vul het contactformulier als je enthousiast
                    wordt van het idee om dit met ons te gaan doen. Dit kan voor
                    een stage van een aantal weken zijn, of voor een langere
                    periode. Alle aangeboden hulp waarderen wij!
                </p>

                <div className="d-flex justify-content-around mt-5">
                    <img
                        className="donate-icon"
                        src={iconPraying}
                        alt="praying"
                    />
                    <img className="donate-icon" src={iconDove} alt="dove" />
                    <img
                        className="donate-icon"
                        src={iconDonation}
                        alt="donation"
                    />
                </div>
            </div>

            {message ? <div className="donation-message">
                <div className="toast show m-3">
                    <div className="toast-header text-primary">
                        <FontAwesomeIcon icon={faBellOn} className="me-2" />
                        <strong className="me-auto">Nieuw bericht</strong>
                        <button type="button" className="btn-close" 
                            onClick={() => setMessage(undefined)} />
                    </div>
                    <div className="toast-body">
                        {message}
                    </div>
                </div>
            </div> : undefined}
        </div>
    );
}

export default Donate;
