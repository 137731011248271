import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Rommelmarkt CGK Zwolle 2019',
        date: moment('2019-06-03 09:18'),
        image: image,
        summary: (
            <>
                De 8 Gezamenlijke Goede Doelen van de CGK Zwolle, hielden mei
                2019 voor de 2<sup>e</sup> keer een grote Rommelmarkt.
            </>
        ),
        content: (
            <>
                <p>
                    De 8 Gezamenlijke Goede Doelen van de CGK Zwolle, hielden
                    &nbsp;mei 2019 voor de 2<sup>e</sup> keer een grote
                    Rommelmarkt. Een aantal dagen van te voren werd er door
                    velen gehoor gegeven aan de oproep om spullen af te leveren
                    bij de Verrijzeniskerk. En op zaterdagmorgen vroeg waren we
                    met velen in touw om alles buiten een plek te geven, want we
                    hadden heerlijk weer. Velen uit de wijk Holtenbroek en vele
                    scharrelaars die rommelmarkten aflopen voor koopjes, maakten
                    op deze manier ook kennis met onze gastvrijheid. Met een
                    gezellige Koek- en Zopiehoek, en veel geschikte spullen voor
                    hergebruik, kunnen we terug zien op een schitterende dag,
                    met ook een mooie opbrengst van ruim €2.200,00. Natuurlijk
                    moeten we dat dan met ons achten delen, maar dan nog zijn we
                    blij met dit mooie bedrag van ongeveer €275,00 per
                    stichting.
                </p>
            </>
        ),
    });

export default useStory;
