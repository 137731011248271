import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Oliebollenactie Jeruzalemkerk Zwolle 2022',
        date: moment('2022-12-24 14:51'),
        image: image,
        summary: (
            <>
                Ook dit jaar werden er weer de nodige oliebollen en appelflappen
                gegeten door gemeenteleden van de Jeruzalemkerk.
            </>
        ),
        content: (
            <>
                <div className="row">
                    <div className="col-12 col-xs-6 col-md-4">
                        <img
                            src={image}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                    <div className="col">
                        Ook dit jaar werden er weer de nodige oliebollen en
                        appelflappen gegeten door gemeenteleden van de
                        Jeruzalemkerk. De nieuwe bakker van vorig jaar, maakte
                        dat er ook dit keer weer fors meer bestellingen waren.
                        Allen heel hartelijk dank voor de mooie opbrengst van
                        €375,00
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
