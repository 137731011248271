import FileCard from '../../FileCard';
import TabNavigation from '../../TabNavigation';
import useNavigationPair from '../../TabNavigation/hooks/useNavigationPair';
import docs from '../../../docs.json';

import './index.scss';

function AboutUs() {
    const pages = [
        useNavigationPair({
            label: 'Algemeen',
            to: '',
            element: (
                <div className="container text-start">
                    <p>
                        <span lang="NL">
                            <strong>Bestuur</strong>
                        </span>
                    </p>
                    <p>
                        <span lang="NL">
                            Het bestuur van Stichting Sarfath bestaat uit:
                        </span>
                    </p>
                    <ul>
                        <li>
                            <span lang="NL">
                                Mevr. A. van Essen – voorzitter
                            </span>
                        </li>
                        <li>
                            <span lang="NL">Mevr. A. Keizer – secretaris</span>
                        </li>
                        <li>
                            <span lang="NL">Mevr. B. Bos – penningmeester</span>
                        </li>
                        <li>
                            <span lang="NL">
                                Mevr. A. Vahl – algemeen bestuurslid
                            </span>
                        </li>
                        <li>
                            <span lang="NL">Dhr. M. Flier – algemeen bestuurslid</span>
                        </li>
                    </ul>
                    <p>
                        <span lang="NL">
                            De bestuursleden genieten geen beloning voor hun
                            werkzaamheden.
                            <br />
                        </span>
                    </p>

                    <p>
                        <strong>ANBI-gegevens</strong>
                    </p>
                    <p>
                        Juridisch gezien is Stichting Sarfath&nbsp;een
                        stichting&nbsp;dat staat ingeschreven bij de Kamer van
                        Koophandel onder ‘STICHTING SARFATH’ en beschikt over
                        een ANBI-status (een Algemeen Nut Beogende Instelling).
                        Hieronder vind je onze ANBI-gegevens op een rij:
                    </p>
                    <ul>
                        <li>Naam stichting: STICHTING SARFATH</li>
                        <li>RSIN: 813984890</li>
                        <li>KVK: 05067879</li>
                        <li>
                            Doelstelling: De stichting ondersteunt projecten in
                            de Arabische wereld, waarin christelijke werkers uit
                            Nederland werkzaam zijn.
                        </li>
                        <li>Telefoonnummer: 038-3322757</li>
                        <li>Email: contact@stichtingsarfath.nl</li>
                        <li>Adres: Tichlerstraat 29, 8271 VD, IJsselmuiden</li>
                    </ul>
                </div>
            ),
        }),
        useNavigationPair({
            label: 'Documenten',
            to: 'docs',
            element: (
                <>
                    <h3 className="mt-3">Beleidsplan</h3>
                    <div className="container d-flex flex-wrap text-start">
                        {docs.beleidsplannen.map((x) => (
                            <FileCard
                                key={x.filename}
                                name={x.title}
                                contentType={x.contentType}
                                target={`${process.env.PUBLIC_URL}/docs/${x.filename}`}
                            />
                        ))}
                    </div>
                    <h3 className="mt-3">Jaarverslagen</h3>
                    <div className="container d-flex flex-wrap text-start">
                        {docs.jaarverslagen.map((x) => (
                            <FileCard
                                key={x.filename}
                                name={x.title}
                                contentType={x.contentType}
                                target={`${process.env.PUBLIC_URL}/docs/${x.filename}`}
                            />
                        ))}
                    </div>
                    <h3 className="mt-3">Jaarrekeningen</h3>
                    <div className="container d-flex flex-wrap text-start">
                        {docs.jaarrekeningen.map((x) => (
                            <FileCard
                                key={x.filename}
                                name={x.title}
                                contentType={x.contentType}
                                target={`${process.env.PUBLIC_URL}/docs/${x.filename}`}
                            />
                        ))}
                    </div>
                </>
            ),
        }),
    ];

    return (
        <div className="page-about-us mt-1 mb-5">
            <TabNavigation items={pages} />
        </div>
    );
}

export default AboutUs;
