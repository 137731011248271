import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';

const useStory = () =>
    useArticle({
        title: 'Stroopwafelactie Eben Haëzer 2022',
        date: moment('2022-06-18 13:38'),
        image: image,
        summary: (
            <>
                Wat hebben de leerlingen zich ontzettend ingespannen om al die
                bekenden en misschien ook wel onbekenden te vragen of ze
                stroopwafels wilden kopen. Jullie zijn toppers!
            </>
        ),
        content: (
            <>
                <p>
                    We willen het team en de leerlingen van de Eben Haëzer heel
                    hartelijk bedanken voor het feit dat we een stroopwafelactie
                    voor Stichting Sarfath mochten houden. Wat hebben de
                    leerlingen zich ontzettend ingespannen om al die bekenden en
                    misschien ook wel onbekenden te vragen of ze stroopwafels
                    wilden kopen. Jullie zijn toppers!
                </p>

                <div className="row">
                    <div className="col">
                        <p>
                            Het resultaat is geweldig!! Er zijn maar liefst 1282
                            pakjes stroopwafels verkocht. We mochten een bedrag
                            ontvangen van €1478,-. Daar kunnen we de medewerkers
                            van de stichting die zich inspannen om kinderen in
                            de Arabische wereld goed onderwijs te geven erg blij
                            mee maken.
                        </p>

                        <p>Heel, heel hartelijk bedankt!!</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <img
                            src={image_2}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
