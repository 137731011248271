import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';

const useStory = () =>
    useArticle({
        title: 'Oliebollenactie CGK Zwolle 2023',
        date: moment('2024-07-13 09:30'),
        image: image,
        summary: (
            <>
                In december stroomden de aanvragen voor Oliebollen en
                appelvlappen weer binnen. Met mensen van de 6 Goede Doelen
                Stichtingen binnen de CGK Zwolle, hebben we weer gesorteerd en
                rondgebracht.
            </>
        ),
        content: (
            <>
                <div className="row">
                    <div className="col-12 col-xs-6 col-md-4">
                        <img
                            src={image_2}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                    <div className="col">
                        In december stroomden de aanvragen voor Oliebollen en
                        appelvlappen weer binnen. Met mensen van de 6 Goede
                        Doelen Stichtingen binnen de CGK Zwolle, hebben we weer
                        gesorteerd en rondgebracht. Samen met de gemeenteleden
                        mochten we zo €3.744,94 aan opbrengst en giften bijeen
                        brengen, dit is per stichting: €627,49. Waarmee
                        kinderen, jongeren en ouders ondersteunen en het
                        fundament leggen voor een hoopvolle toekomst.
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
