import { Routes } from "react-router";
import useNavigationCollection from "./hooks/useNavigationCollection";
import { NavigationPair } from "./hooks/useNavigationPair";

function TabNavigation({ items }: { items: NavigationPair[]; }) {
    const [routes, navs] = useNavigationCollection(...items);

    return (<>
        <ul className="nav nav-tabs">
            {navs}
        </ul>

        <Routes>
            {routes}
        </Routes>
    </>);
}

export default TabNavigation;