import './index.scss';
import ArticleOverview from '../../ArticleOverview';
import useArticlesLibrary from '../../articles';

function Blog() {
    const articles = useArticlesLibrary();

    return (
        <div className="page-blog my-5 container">
            <ArticleOverview articles={articles} />
        </div>
    );
}

export default Blog;
