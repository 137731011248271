import { useCallback, useState } from 'react';
import './index.scss';
import Message from '../../../models/Message';

const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    
    const [nameValidation, setNameValidation] = useState('');
    const [emailValidation, setEmailValidation] = useState('');
    const [subjectValidation, setSubjectValidation] = useState('');
    const [messageValidation, setMessageValidation] = useState('');

    const validate = useCallback(() => {
        let valid = true;
        
        if (!name) {
            setNameValidation('Vul a.u.b. een naam in.');
            valid = false;
        } else {
            setNameValidation('')
        }
        if (!email) {
            setEmailValidation('Vul a.u.b. uw email adres in. Hiermee nemen wij contact met u op.');
            valid = false;
        } else if (!REGEX_EMAIL.test(email)) {
            setEmailValidation('Geef a.u.b. een geldig email adres op.');
            valid = false;
        } else {
            setEmailValidation('')
        }
        if (!subject) {
            setSubjectValidation('Vul a.u.b. een onderwerp in.');
            valid = false;
        } else {
            setSubjectValidation('')
        }
        if (!message) {
            setMessageValidation('Vul a.u.b. een bericht in.');
            valid = false;
        } else {
            setMessageValidation('')
        }

        return valid;
    }, [email, message, name, subject]);

    const send = useCallback(async () => {
        if (!validate()) return;

        const data: Message = {
            name, email, subject, message
        };

        const response = await fetch(`${process.env.PUBLIC_URL}/_contact.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
        }
    }, [email, message, name, subject, validate]);

    return (
        <div className="page-contact my-5">
            <div className="container text-start">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        Heb je vragen over het werk dat wij doen? Of wil je jouw
                        verhaal met ons delen? Neem dan contact met ons op via
                        onderstaand formulier en wij laten van ons horen.
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        We staan ook open voor mensen die dit werk een warm hart
                        toedragen en willen helpen met het verder uitbouwen van
                        onze organisatie. Ben jij dit? Reageer dan ook via
                        onderstaand formulier!
                    </div>
                </div>
            </div>

            <div className="container text-start">
                <h5>Contactformulier</h5>

                <div className="form-group has-validation col-12 col-md-6">
                    <label>Je naam (verplicht)</label>
                    <input className={`form-control${!!nameValidation ? ' is-invalid' : ''}`} type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    {!!nameValidation ? <div className='invalid-feedback'>{nameValidation}</div> :  undefined}
                </div>

                <div className="form-group has-validation col-12 col-md-6">
                    <label>Je e-mailadres (verplicht)</label>
                    <input className={`form-control${!!emailValidation ? ' is-invalid' : ''}`} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {!!emailValidation ? <div className='invalid-feedback'>{emailValidation}</div> :  undefined}
                </div>

                <div className="form-group has-validation col-12 col-md-6">
                    <label>Onderwerp</label>
                    <input className={`form-control${!!subjectValidation ? ' is-invalid' : ''}`} type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    {!!subjectValidation ? <div className='invalid-feedback'>{subjectValidation}</div> :  undefined}
                </div>

                <div className="form-group has-validation col-12 col-md-6">
                    <label>Je bericht</label>
                    <textarea className={`form-control${!!messageValidation ? ' is-invalid' : ''}`} rows={8} value={message} onChange={(e) => setMessage(e.target.value)} />
                    {!!messageValidation ? <div className='invalid-feedback'>{messageValidation}</div> :  undefined}
                </div>

                <button className="btn btn-secondary mt-3 text-light" onClick={() => send()}>
                    Verzenden
                </button>
            </div>
        </div>
    );
}

export default Contact;
