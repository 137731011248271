import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Stroopwafelactie CGK Zwolle 2022',
        date: moment('2022-02-28 10:28'),
        image: image,
        summary: (
            <>
                In februari zijn de kinderen binnen de CGK op pad geweest met
                intekenlijsten, waarop mensen aankonden geven hoeveel pakjes
                stroopwafels ze wilden bestellen.
            </>
        ),
        content: (
            <>
                <div className="row">
                    <div className="col-12 col-xs-6 col-md-4">
                        <img
                            src={image}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                    <div className="col">
                        In februari zijn de kinderen binnen de CGK op pad
                        geweest met intekenlijsten, waarop mensen aankonden
                        geven hoeveel pakjes stroopwafels ze wilden bestellen.
                        De kinderen die mee deden maakten er een mooi feest van,
                        waarbij ze probeerden van elkaar te winnen. Samen met
                        alle kopers allemaal heel hartelijk bedankt. Per
                        stichting mochten we €275,00 ontvangen. Ze mogen we
                        samen bezig zijn, jong en ouder, voor de mensen die het
                        zoveel minder hebben dan wij.
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
