import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.png';

const useStory = () =>
    useArticle({
        title: 'Oliebollenactie 2018',
        date: moment('2018-12-22 14:03'),
        image: image,
        summary: (
            <>
                In december 2018 werd vanuit de CGK Zwolle een gezamenlijke
                goede doelenactie georganiseerd.
            </>
        ),
        content: (
            <>
                <img
                    className="img-fluid"
                    width="871"
                    height="490"
                    src={image_2}
                    alt=""
                />

                <p>
                    In december 2018 werd vanuit de CGK Zwolle een gezamenlijke
                    goede doelenactie georganiseerd.
                </p>

                <p>
                    De organisatie verliep weer vlekkeloos. Bestellen kon via de
                    site. Heerlijke oliebollen die zich in de voorgaande jaren
                    al bewezen hadden, werden weer volop besteld.
                </p>

                <p>
                    Met en zonder krenten en ook nog verrukkelijke appelflappen.
                    Op maandagmorgen 31 december gingen er weer velen op pad,
                    waarna al die auto`s nog tijden bleven ruiken naar de
                    oliebollen. Soms zoeken naar een adres maar uit eindelijk
                    zijn alle bollen en flappen op tijd bezorgd, zodat er weer
                    door velen gesmuld is en dan ook nog eens voor de mensen en
                    de activiteiten die gesteund worden door de verschillende
                    Goede Doelen. Al etend is er samen €1950,00 opgebracht
                </p>
            </>
        ),
    });

export default useStory;
