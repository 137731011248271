import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useCallback, useMemo } from 'react';
import { useResolvedPath } from 'react-router';
import { Route, To, useNavigate } from 'react-router';
import { useMatch } from 'react-router';

export type NavigationPair = [route: JSX.Element, nav: JSX.Element];

function useNavigationPair({ label, icon, to, disabled = false, element }: { label: string, icon?: IconDefinition; to: To; disabled?: boolean; element?: JSX.Element | string | null; }) {
    const navigate = useNavigate();
    const { pathname } = useResolvedPath(to, {
        relative: 'route'
    });

    const path = useMemo(() => typeof to === 'string' ? to : to.pathname ?? '', [to]);

    const match = useMatch({
        path: pathname,
        end: true
    });

    const onClick = useCallback(() => navigate(to), [navigate, to])

    const nav = (
        <li key={pathname} className="nav-item flex-fill d-flex">
            <button className={`nav-link flex-fill mx-1${match ? ' active' : ' text-muted'}`} disabled={disabled} onClick={onClick}>
                {label}
            </button>
        </li>
    );
    const route = <Route key={pathname}
        path={path}
        element={!disabled ? element : undefined}
    />;

    return [route, nav] as NavigationPair;
}

export default useNavigationPair;
