import { useMemo } from 'react';
import BlogCard from '../../../BlogCard';
import { Route, useNavigate } from 'react-router';
import { snakeCase } from 'lodash';
import './index.scss';
import { Moment } from 'moment';
import { useLocation } from 'react-router';

export type Article = [JSX.Element, JSX.Element, Moment];

function useArticle({
    title,
    date,
    summary,
    image,
    content,
}: {
    title: string;
    date: Moment,
    summary: string | JSX.Element;
    image: string;
    content: string | JSX.Element;
}) {
    const { pathname } = useLocation();
    const id = useMemo(() => snakeCase(title), [title]);
    const navigate = useNavigate();

    const card = <BlogCard title={title} date={date} image={image} to={id} key={`${pathname}-card-${id}`}>
        {summary}
    </BlogCard>;

    const route = <Route
        path={id}
        element={
            <div className="article text-start position-relative" key={`${pathname}-content-${id}`}>
                <h1>{title}</h1>
                {content}
                
                <button className="article-btn-back btn btn-link position-absolute" onClick={() => navigate('.')}>
                    terug
                </button>
            </div>
        }
        key={`${pathname}-route-${id}`}
    />;

    return [route, card, date] as Article;
}

export default useArticle;
