import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.png';
import image_3 from './image_3.png';

const useStory = () =>
    useArticle({
        title: 'Goede Doelenmaaltijd 2017',
        date: moment('2017-05-18 13:01'),
        image: image,
        summary: (
            <>
                In samenwerking met de Gift City bieden de Goede Doelen van de
                CGK Zwolle u de ‘Goede Doelen Maaltijd’ aan, waarbij u de keuze
                hebt tussen hutspot en boerenkoolstamppot.
            </>
        ),
        content: (
            <>
                <p>
                    In samenwerking met de Gift City bieden de Goede Doelen van
                    de CGK Zwolle u de ‘Goede Doelen Maaltijd’ aan, waarbij u de
                    keuze hebt tussen hutspot en boerenkoolstamppot.
                </p>

                <img
                    className="img-fluid"
                    width="225"
                    height="300"
                    src={image_2}
                    alt=""
                />

                <p>
                    Na een heel geslaagde actie van vorig jaar, is er ook dit
                    jaar weer op 3 zaterdagavonden een stamppotbuffet
                    aangeboden. We mogen terug zien op een heel gezellig,
                    smakelijk samen zijn. Daarnaast is het natuurlijk fijn dat
                    deze activiteit ook een goede opbrengst met zich meebrengt
                    voor de verschillende Goede Doelen. Samen hebben we ruim 550
                    maaltijden verzorgd. Na het verrekenen van de kosten zal er
                    aan ieder goed doel ongeveer €450,00 worden overgemaakt.
                </p>

                <img
                    className="img-fluid"
                    loading="lazy"
                    width="225"
                    height="300"
                    src={image_3}
                    alt=""
                />

                <p>
                    Het uiteindelijke bedrag zal nog bekend gemaakt worden. Dit
                    schitterende bedrag was alleen mogelijk door
                    dat&nbsp;&nbsp;heel veel benodigdheden werden gesponsord.
                    Hier voor onze hartelijke dank!
                </p>
            </>
        ),
    });

export default useStory;
