import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';
import image_3 from './image_3.jpg';
import image_4 from './image_4.jpg';
import image_5 from './image_5.jpg';

const useStory = () =>
    useArticle({
        title: 'Stroopwafelactie 2020',
        date: moment('2021-05-12 17:07'),
        image: image,
        summary: (
            <>
                Eind oktober mochten we op de Rehoboth en de Ichthus in
                IJsselmuiden een stroopwafelactie houden.
            </>
        ),
        content: (
            <>
                <p>
                    Eind oktober mochten we op de Rehoboth en de Ichthus in
                    IJsselmuiden een stroopwafelactie houden.
                    <br />
                    De leerlingen kregen lijsten waarmee ze familie en buren
                    konden vragen stroopwafels te kopen. Ook was er een brief
                    bij met uitleg over Stichting Sarfath en het werk van
                    MensRijk. Voordat de kinderen van start gingen konden ze een
                    filmpje zien over het werk van MensRijk. Erg leuk om zo
                    betrokken te zijn bij het doel van de actie.
                    <br />
                    En wat hebben ze hun best gedaan op beide scholen!!! We
                    keken met verbazing naar de grote hoeveelheid dozen met
                    stroopwafels.
                    <br />
                    Voor Dankdag konden de kinderen de stroopwafels bij alle
                    adressen bezorgen. Via een video konden we hen bedanken.
                    <br />
                    Het leverde het geweldige bedrag op van ruim €4200.
                    <br />
                    Rehoboth en Ichthus: heel , heel hartelijk bedankt!!!
                </p>

                <img
                    className="img-fluid"
                    width="576"
                    height="1024"
                    src={image_2}
                    alt=""
                />

                <img
                    className="img-fluid"
                    width="1024"
                    height="576"
                    src={image_3}
                    alt=""
                />

                <img
                    className="img-fluid"
                    width="640"
                    height="480"
                    src={image_4}
                    alt=""
                />

                <img
                    className="img-fluid"
                    width="768"
                    height="1024"
                    src={image_5}
                    alt=""
                />

                <img className="img-fluid" src={image} alt="" />
            </>
        ),
    });

export default useStory;
