import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';

const useStory = () =>
    useArticle({
        title: 'Verkoopactie 2020',
        date: moment('2020-03-11 14:09'),
        image: image,
        summary: (
            <>
                Dit seizoen was de opbrengst van het potje voor Stichting
                Sarfath en hielden de dames op 27 februari een verkoopochtend
                waarvan de opbrengst ook voor Stichting Sarfath zou worden
                bestemd.
            </>
        ),
        content: (
            <>
                <p>
                    Dit seizoen was de opbrengst van het potje voor Stichting
                    Sarfath en hielden de dames op 27 februari een
                    verkoopochtend waarvan de opbrengst ook voor Stichting
                    Sarfath zou worden bestemd. Ze hadden zelf allerlei zaken
                    ingebracht, die onder opbod verkocht gingen worden. Wij
                    werden gevraagd of we,&nbsp; voorafgaand aan de verkoop, een
                    presentatie wilden houden over het werk. Annie en Anneke
                    hebben een PowerPoint presentatie gehouden en genoten van de
                    ludieke verkoop praktijken. Die dreven de opbrengst aardig
                    op. Zo werden we verblijd met een gift van €225,00, waar we
                    de dames heel hartelijk voor hebben bedankt.
                </p>

                <img
                    className="img-fluid"
                    width="850"
                    height="637"
                    src={image_2}
                    alt=""
                />
            </>
        ),
    });

export default useStory;
