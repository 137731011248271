import { Link, To } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { Moment } from 'moment';
import './index.scss';

function BlogCard({
    title,
    date,
    children,
    image,
    to,
}: PropsWithChildren<{
    title: string;
    date: Moment;
    image: string;
    to: To;
}>) {
    return (
        <div className="blog-card p-3 col-12 col-md-6 col-lg-4">
            <div className="card shadow-sm">
                <img src={image} className="card-img-top" alt="..." />
                <div className="card-body d-flex flex-column">
                    <h5 className="card-title">{title}</h5>

                    <small className="blog-card-date text-end me-1">
                        {date.format('LLLL')}
                    </small>

                    <p className="card-text flex-fill">{children}</p>

                    <Link className="btn btn-outline-primary" to={to}>
                        Lees meer
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default BlogCard;
