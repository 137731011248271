import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.png';

const useStory = () =>
    useArticle({
        title: 'Christelijk Gereformeerde Kerk Zwolle, 2024',
        date: moment('2025-01-08 07:01'),
        image: image,
        summary: (
            <>
                In het afgelopen jaar zijn er binnen de CGK weer verschillende
                acties op touw gezet door de Gezamenlijke Goede Doelen, tevens
                kregen we met ons zessen de opbrengst van 3 collectes te delen.
            </>
        ),
        content: (
            <>
                <div className="row mb-3">
                    <p>
                        In het afgelopen jaar zijn er binnen de CGK weer
                        verschillende acties op touw gezet door de Gezamenlijke
                        Goede Doelen, tevens kregen we met ons zessen de
                        opbrengst van 3 collectes te delen.{' '}
                        <strong>
                            Bij elkaar opgeteld, kregen we in het afgelopen
                            jaar: €3.850,00.
                        </strong>
                    </p>
                    <p>
                        Hiervoor zijn we als gezamenlijke stichtingen actief
                        geweest met het bakken van pannenkoeken, om zo met bijna
                        100 gemeenteleden een waar pannenkoekenfestijn,
                        inclusief goochelaar, te vieren. In juli waren we
                        verschillende dagen in de weer om de rommelmarkt voor te
                        bereiden waarop we op 13 juli samen een super gezellig
                        samen zijn hadden. Helaas moesten we dit i.v.m. regen in
                        de Verrijzeniskerk binnen houden, maar dat mocht de pret
                        niet drukken. Onder het genot van koffie, thee of ranja
                        en overheerlijke koeken en wat later op de ochtend ook
                        nog eens de mogelijkheid om pannenkoeken, broodjes
                        hamburger of knakworstjes te eten, mochten we ook deze
                        dag weer succesvol afsluiten.
                    </p>
                    <p>
                        Bij de acties en natuurlijk ook door het geven van
                        giften, gaat ook hier weer op: “Vele handen maken licht
                        werk.” Allen weer super bedankt!
                    </p>
                </div>
            </>
        ),
    });

export default useStory;
