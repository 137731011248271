import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';

const useStory = () =>
    useArticle({
        title: 'Stroopwafelactie 2019',
        date: moment('2019-09-13 09:40'),
        image: image,
        summary: (
            <>
                In september 2019 mochten we, bij de Jeruzalemkerk in Zwolle,
                als Sarfath samen met Sunrise Dieze&nbsp;&nbsp;en het project
                Kandila in Roemenie, voor de derde keer een stroopwafelactie
                houden.
            </>
        ),
        content: (
            <>
                <p>
                    In september 2019 mochten we, bij de Jeruzalemkerk in
                    Zwolle, als Sarfath samen met Sunrise Dieze&nbsp;&nbsp;en
                    het project Kandila in Roemenie, voor de derde keer een
                    stroopwafelactie houden.
                </p>

                <p>
                    Binnen de Jeruzalemkerkgemeente zijn er vele stroopwafels
                    verkocht en gegeten. In een maand tijd en door inzet van
                    vele vrijwilligers, jong en oud, bracht deze actie ruim
                    €1900,00 op, mede door een extra&nbsp;&nbsp;bijdrage van de
                    Centrale diaconie van PKN Zwolle.&nbsp;&nbsp;Zo konden we
                    met elkaar mensen in Zwolle, Roemenië en de Arabische wereld
                    steunen. Een mooi bewijs van: Vele handen maken licht werk!
                    Als bestuur van Sarfath, zijn we dankbaar en blij dat we met
                    elkaar zo geld bij elkaar mogen brengen voor de
                    verschillende projecten. Dank jullie wel allemaal.
                </p>

                <img
                    className="img-fluid"
                    width="344"
                    height="258"
                    src={image_2}
                    alt=""
                />
            </>
        ),
    });

export default useStory;
