import { useMemo } from 'react';
import { NavigationPair } from './useNavigationPair';

export type NavigationCollection = [routes: JSX.Element[], navs: JSX.Element[]];

function useNavigationCollection(...items: NavigationPair[]) {
    const routes = useMemo(() => items.map(([x]) => x), [items]);
    const navs = useMemo(() => items.map(([,x]) => x), [items]);

    return [routes, navs] as NavigationCollection;
}

export default useNavigationCollection;
