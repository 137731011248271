import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Oliebollenactie Jeruzalemkerk Zwolle 2019',
        date: moment('2020-01-02 09:45'),
        image: image,
        summary: (
            <>
                Door de zendingscommissie van de Jeruzalemkerk is er dit jaar
                een oliebollenactie gehouden. Er zijn 1200 oliebollen en 170
                appelbeignets besteld.
            </>
        ),
        content: (
            <>
                <p>
                    Door de zendingscommissie van de Jeruzalemkerk is er dit
                    jaar een oliebollenactie gehouden. Er zijn 1200 oliebollen
                    en 170 appelbeignets besteld.&nbsp; Waar rond de
                    jaarwisseling heerlijk van is gesmuld.&nbsp; De opbrengst is
                    voor Stichting Sarfath en bedraagt €450,=. Waar we met
                    elkaar heel blij mee zijn.
                </p>
            </>
        ),
    });

export default useStory;
