import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Oliebollenactie CGK Zwolle 2022',
        date: moment('2022-12-24 12:31'),
        image: image,
        summary: (
            <>
                De oliebollen en appelflappen waren bijna niet aan te slepen.
                Met de Gezamenlijke Goede Doelen stichtingen, hebben we op 31
                december oliebollen en appelflappen gesorteerd en rondgebracht.
            </>
        ),
        content: (
            <>
                <div className="row">
                    <div className="col-12 col-xs-6 col-md-4">
                        <img
                            src={image}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                    <div className="col">
                        Wat een super opbrengst dit jaar. De oliebollen en
                        appelflappen waren bijna niet aan te slepen. Met de
                        Gezamenlijke Goede Doelen stichtingen, hebben we op 31
                        december oliebollen en appelflappen gesorteerd en
                        rondgebracht. Onder leiding van Jaap was iedereen super
                        enthousiast, mede door de bijzonder hoge opbrengst van
                        €421,25 per stichting. Alle oliebollen en appelflappen
                        etende CGK-ers heel hartelijk bedankt!
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
