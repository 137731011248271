import { PropsWithChildren, useMemo } from 'react';
import './index.scss';

function ImageBlock({
    children,
    src,
    className = '',
}: PropsWithChildren<{
    src: string;
    className?: string;
}>) {
    const internalClassName = useMemo(
        () =>
            [
                'image-block d-flex justify-content-center align-items-center',
                className,
            ].join(' '),
        [className]
    );

    return (
        <div className={internalClassName}>
        <img src={src} className="img-fluid w-100" alt="..." />
            <div className="image-watermark text-light m-3">
                {children}
            </div>
        </div>
    );
}

export default ImageBlock;
